ul.openinghours {
    list-style: none;
    margin: 20px 0;
    padding: 0;
}

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 10px;
}
