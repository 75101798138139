/* work-sans-900 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 900;
  src: local(''),
       url('./assets/work-sans-v17-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./assets/work-sans-v17-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Dalek Pinpoint';
  src: url('./assets/DalekPinpointBold.woff2') format('woff2'),
      url('./assets/DalekPinpointBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

h2 {
  font-family: 'Dalek Pinpoint';
  text-transform: uppercase;
  color: #fff;
  padding: 25px 20px 15px;
  text-align: center;
  margin: -5px 0 0 0;
  background-color: #393D3E;
  letter-spacing: 0.05em;
}

.distance-top {
  margin-top: 10px;
}

.highlight {
  background-image: url(./assets/header-bg.png);
  height: 9px;
  background-size: 100% auto;
}

.App-logo {
  height: 4vmin;
  pointer-events: none;
}

.App-header {
  background-color: #393D3E;
  min-height: 12vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  border-bottom: 10px solid #726E60;
}

.App-link {
  color: #61dafb;
}

.headerimg {
  width: 100%;
}

.center {
  text-align: center;
}

.hint {
  background: rgb(2,149,0);
  background: radial-gradient(circle, rgba(2,149,0,1) 0%, rgba(13,88,7,1) 47%, rgba(8,103,16,1) 100%);
  padding: 10px;
  text-align: center;
  color: #fff;
}

.hint .inner {
  max-width: 600px;
  margin: 0 auto;
}
